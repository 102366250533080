import { Theme } from "apps/website/maps/Theme.map";
import {
  textStickerThemeMap,
} from "apps/website/components/base/Text/Text.map";

// The pattern for replacing words is:
// This is my [[<title><comment>]]
export const replaceWordsWithSticker = (string: string, stickerSize: string, theme: Theme = "default") => {
  const replace = /\[\[(.*?)\]\]/;
  const replaceWords = /<(.*?)>/g;

  const replaceString = replace.exec(string);

  if (!replaceString) return string;

  const toBeReplaced = replaceWords.exec(replaceString[1].match(replaceWords)?.[0] ?? "");
  const toReplace = replaceWords.exec(replaceString[1].match(replaceWords)?.[1] ?? "");

  if (!toBeReplaced || !toReplace) return string;

  const toBeReplacedWord = toBeReplaced[1];
  const toReplaceWord = toReplace[1];

  return string.replace(replace, `
  <span class="relative overflow-hidden">
    <strike class="no-underline">${toBeReplacedWord}</strike>
    <span class="absolute flex justify-center items-center w-full h-full inset-0">
      <span class="transform -rotate-6 w-fit h-fit" data-theme="${theme}" style="font-size: ${stickerSize}">
        <span class="${textStickerThemeMap[theme]} px-2">${toReplaceWord}</span>
      </span>
    </span>
  </span>
  `);
};

export const makeTitleFromArray = (titleArray: string[]): string => titleArray?.join("<br>");

export const getReadingTime = (number: number) => `${number} min`;

// Taking an array of strings [X, Y, Z], produces a string in the form "X, Y and Z".
// Taking an array of strings [X, Y], produces a string in the form "X and Y".
// Taking an array of strings [X], produces a string in the form "X".
// Taking an empty array or non-array, produces an empty string.
export function joinNames(toJoin: string[], separator = ", ", finalSeparator = " and "): string {
  if (!toJoin || !Array.isArray(toJoin) || toJoin.length <= 0) return "";
  if (toJoin.length === 1) return toJoin[0];
  return `${toJoin.slice(0, toJoin.length - 1).join(separator)}${finalSeparator}${toJoin[toJoin.length - 1]}`;
}

export function capitalizeFirst(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const slugify = (slug: string, separator = "-"): string => slug.toString()
  .normalize("NFD") // split an accented letter in the base letter and the acent
  .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
  .toLowerCase()
  .trim()
  .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
  .replace(/\s+/g, separator);
