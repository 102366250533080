import { FC } from "react";
import Link from "next/link";

import { IImageProps } from "apps/website/components/base/Image/Image";
import { ITagProps } from "apps/website/components/base/Tag/Tag";
import Text from "apps/website/components/base/Text/Text";
import Icon from "apps/website/components/base/Icon/Icon";
import { getReadingTime } from "apps/website/utils/content/text";
import Card from "apps/website/components/feature/Card/Card";
import { Theme } from "apps/website/maps/Theme.map";
import { WithTestID } from "apps/website/types";

export interface IArticleCard extends WithTestID {
  title: string;
  description: string;
  image?: IImageProps;
  link?: string;
  tag?: ITagProps;
  readTime: number;
  theme?: Theme;
}

const ArticleCard: FC<IArticleCard> = ({
  title,
  description,
  image,
  link,
  tag,
  readTime,
  theme = "dark",
  "data-testid": testId,
}) => {

  const cardFooter = () => (
    <>
      { readTime && <Text tag="span" display="title">{ getReadingTime(readTime) }</Text> }
      { link && (
        (<Link
          href={link}
          className="flex items-center opacity-100  hover:opacity-70 transition-opacity duration-300"
          aria-label={`Read more about ${title}`}>

          <Text tag="span" className="mr-2">Read on</Text>
          <Icon icon="plus" />

        </Link>)
      ) }
    </>
  );

  return (
    <Card
      data-testid={`article-${testId}`}
      component="ArticleCard"
      title={title}
      link={link}
      description={description}
      image={image}
      theme={theme}
      tag={tag}
      ctaAction="link"
      Footer={cardFooter}
      className="transform rotate-0 hover:rotate-1 transition-transform ease-in-out duration-300"
    />
  );
};

export default ArticleCard;
