import { useState, useEffect } from "react";

export default function useClient() {
  const [ isSSR, setIsSSR ] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  return {
    isSSR,
    isClient: !isSSR,
  };
}
