"use client";

import { FC } from "react";
import { SwiperSlide } from "swiper/react";

import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Carousel from "apps/website/components/feature/Carousel/Carousel";
import TestimonialReview, {
  ITestimonialReview,
} from "apps/website/components/feature/Review/TestimonialReview/TestimonialReview";
import { Theme } from "apps/website/maps/Theme.map";
import { getCardTheme } from "apps/website/utils/content/carousels";

export interface ITestimonialReviewCarousel {
  items: ITestimonialReview[],
  theme?: Theme;
}

const TestimonialReviewCarousel: FC<ITestimonialReviewCarousel> = ({ items, theme = "dark" }) => (
  <Carousel component="TestimonialReviewCarousel" breakpoints={{ default: 1 }} overflow="visible" loop>
    { items.map((review) => (
      <SwiperSlide key={review.title}>
        <TestimonialReview {...review} theme={getCardTheme(theme)}/>
      </SwiperSlide>
    )) }
    <Spacer size="xl" />
  </Carousel>
);

export default TestimonialReviewCarousel;
