import {
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";

export interface IOptions {
  root: Element | Document | null,
  rootMargin: string,
  threshold: number;
}

const INTERSECTION_DEFAULTS: IOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 0.5,
};

export default function useIntersectionObserver(options: IOptions = INTERSECTION_DEFAULTS) {
  const containerRef = useRef<HTMLElement>(null);
  const [ isVisible, setIsVisible ] = useState(false);

  const callbackFunction = useCallback((entries?: IntersectionObserverEntry[]) => {
    if (entries) {
      const [ entry ] = entries;
      setIsVisible(entry.isIntersecting);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    const container = containerRef.current;
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (container) observer.unobserve(container);
    };
  }, [ containerRef, callbackFunction ]);

  return [ containerRef, isVisible ];
}
