"use client";

import { FC } from "react";
import { SwiperSlide } from "swiper/react";

import Carousel from "apps/website/components/feature/Carousel/Carousel";

import FreshPlanOverview, {
  IFreshPlanOverviewProps,
} from "../../FreshPlanOverview/FreshPlanOverview";

export interface IFreshPlanOverviewCarouselProps {
  items: IFreshPlanOverviewProps[];
}

const FreshPlanOverviewCarousel: FC<IFreshPlanOverviewCarouselProps> = ({ items }) => (
  <Carousel component="ImageCarousel" breakpoints={ { default: 1 } } pagination={ true }>
    { items.map((item, index) => (
      <SwiperSlide key={ `image-${index}` } className="!h-auto">
        <FreshPlanOverview { ...item } />
      </SwiperSlide>
    )) }
  </Carousel>
);

export default FreshPlanOverviewCarousel;
