import { FC } from "react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Grid from "apps/website/components/layout/Grid/Grid";
import Text from "apps/website/components/base/Text/Text";
import Column from "apps/website/components/layout/Column/Column";

export interface ICondensedImageCardCarouselItemProps {
  image: IImageProps;
  title: string;
  theme: Theme;
}

const CondensedImageCardCarouselItem: FC<ICondensedImageCardCarouselItemProps> = ({ image, title, theme }) => (
  <div data-component={CondensedImageCardCarouselItem.name} data-theme={theme} className={`h-full ${themeRootClassMap[theme]} ${theme !== "default" ? "p-4" : ""}`}>
    <Grid>
      <Column spans={4} justify="center" align="center">
        <Image image={image} alt={title} />
      </Column>
      <Column spans={8} align="center" justify="center">
        <Text display="subtitle" size={{ default: "sm", lg: "2xl" }}>{ title }</Text>
      </Column>
    </Grid>
  </div>
);

export default CondensedImageCardCarouselItem;
