// Available tags for the Text component
export const availableTags = [ "h1", "h2", "h3", "h4", "h5", "h6", "p", "span", "address", "strong", "a", "div", "u", "s", "blockquote", "figcaption", "del" ] as const;

// Color options
export const colorVariants = {
  inherit: "text-[var(--color-text-inherit)]",
  default: "text-[var(--color-text-default)] transition transition-color ease-in-out duration-300",
  secondary: "text-[var(--color-text-secondary)]",
  tertiary: "text-[var(--color-text-tertiary)]",
  disabled: "text-[var(--color-text-disabled)]",
  iconDisabled: "text-[var(--color-text-icon-disabled)] text-opacity-50",
  error: "text-[var(--color-text-error)]",
  required: "text-[var(--color-text-required)]",
  success: "text-[var(--color-text-success)]",
};

// Alignment options
export const alignVariants = {
  default: "text-left",
  right: "text-right",
  center: "text-center",
  inherit: "text-inherit",
};

// Whitespace options
export const whitespaceVariants = {
  normal: "whitespace-normal",
  preWrap: "whitespace-pre-wrap",
  noWrap: "whitespace-nowrap",
  balance: "whitespace-normal text-balance",
};

// Weight options
export const weightVariants = {
  normal: "font-normal",
  bold: "font-bold",
};

// Responsive variants
export const responsiveVariants = [ "sm", "md", "lg", "xl" ] as const;
