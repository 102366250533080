import { FC, memo } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import Review, {
  ReviewStarStyle,
} from "apps/website/components/feature/Review/Review";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";

export interface ITestimonialReview {
  id: string;
  stars?: number,
  hideStars?: boolean;
  starStyle?: ReviewStarStyle;
  title: string;
  body: string;
  author?: string;
  theme?: Theme;
  image: IImageProps;
}

const TestimonialReview: FC<ITestimonialReview> = ({ title, body, author, theme, image, stars, hideStars = false, starStyle = "default" }) => (
  <Grid gap="none" className="transform rotate-1" component="TestimonialReview">
    <Column spans={7}>
      <Review
        title={title}
        titleDisplay="subtitle"
        body={body}
        author={author}
        theme={theme}
        stars={stars}
        hideStars={hideStars}
        starStyle={starStyle}
        className="h-full"
      />
    </Column>
    <Column spans={5} className="relative">
      <div className="aspect-square">
        <Image image={image} alt={image.alt || title} cover/>
      </div>
    </Column>
  </Grid>
);

export default memo(TestimonialReview);
