import { FC, ReactNode, memo } from "react";

import { WithDataAttr } from "apps/website/types";
import cx from "apps/website/utils/misc/cx";

import { TSubtitleTag } from "../types";

import textSubtitleStyles, { TextSubtitleVariantsProps } from "./styles";

export interface ISubtitleProps extends WithDataAttr, TextSubtitleVariantsProps {
  id?: string;
  tag?: TSubtitleTag

  children: ReactNode;
  className?: string;
}

const TextSubtitle: FC<ISubtitleProps> = ({
  id,
  tag: Tag = "h3",
  children,
  "data-testid": testId,
  size,
  align,
  whitespace,
  color,
  weight,
  className,
}) => (
  <Tag
    id={id}
    data-testid={testId}
    className={cx(textSubtitleStyles({ size, align, whitespace, color, weight }), className)}
  >
    { children }
  </Tag>
);

export default memo(TextSubtitle);
