export const cardHoverClassMap = {
  default: "",
  article: "transition-transform duration-300 transform rotate-0 hover:rotate-2",
  product: "",
};

export const aspectRatioClassMap = {
  default: "aspect-[4/3]",
  "3/4": "aspect-[3/4]",
};

export type Hover = keyof typeof cardHoverClassMap;
export type AspectRatio = keyof typeof aspectRatioClassMap;
