"use client";

import { FC } from "react";
import { SwiperSlide } from "swiper/react";

import Carousel from "apps/website/components/feature/Carousel/Carousel";
import CondensedImageCardCarouselItem, {
  ICondensedImageCardCarouselItemProps,
} from "apps/website/components/feature/Carousel/CondensedImageCardCarousel/CondensedImageCardCarouselItem/CondensedImageCardCarouselItem";

export interface ICondensedImageCardCarouselProps {
  items: ICondensedImageCardCarouselItemProps[];
}

const CondensedImageCardCarousel: FC<ICondensedImageCardCarouselProps> = ({ items }) => (

  <Carousel component={CondensedImageCardCarousel.name} breakpoints={{ default: 1.33 }}>
    { items.map((item) => (
      <SwiperSlide key={item.title}>
        <CondensedImageCardCarouselItem {...item} />
      </SwiperSlide>
    )) }
  </Carousel>
);

export default CondensedImageCardCarousel;
