import { FC, memo } from "react";

import { IImageProps } from "apps/website/components/base/Image/Image";
import { Theme } from "apps/website/maps/Theme.map";
import Review from "apps/website/components/feature/Review/Review";
import { Layout } from "apps/website/components/feature/Review/Review.map";

export type IStars = 1 | 2 | 3 | 4 | 5;

export interface IExternalReview {
  title: string;
  body: string;
  author?: string;
  stars?: IStars;
  hideStars?: boolean;
  authorLogo: IImageProps;
  className?: string;
  theme?: Theme;
  layout?: Layout;
}

const ExternalReview: FC<IExternalReview> = ({ title, body, author, stars, hideStars, authorLogo, className, theme = "default", layout }) => (
  <Review
    component="ExternalReview"
    stars={stars}
    hideStars={hideStars}
    title={title}
    body={body}
    author={author}
    authorLogo={authorLogo}
    theme={theme}
    className={className}
    layout={layout}
  />
);

export default memo(ExternalReview);
