"use client";

import { FC } from "react";
import { SwiperSlide } from "swiper/react";

import Carousel from "apps/website/components/feature/Carousel/Carousel";
import ExternalReview, {
  IExternalReview,
} from "apps/website/components/feature/Review/ExternalReview/ExternalReview";
import { Theme } from "apps/website/maps/Theme.map";
import { Layout } from "apps/website/components/feature/Review/Review.map";
import { getCardTheme } from "apps/website/utils/content/carousels";

export interface ITrustPilotCarousel {
  items: IExternalReview[],
  theme?: Theme;
  layout?: Layout
}

const TrustPilotCarousel: FC<ITrustPilotCarousel> = ({ items, theme, layout }) => (
  <Carousel component="TrustPilotCarousel" breakpoints={{ default: 1, lg: 1, xl: 1.5 }} overflow="visible" loop>
    { items.map((review) => (
      <SwiperSlide key={review.title} className="flex" style={{ height: "auto" }}>
        <ExternalReview {...review} layout={layout} className="transform -rotate-1" theme={getCardTheme(theme ?? "light-grey")}/>
      </SwiperSlide>
    )) }
  </Carousel>
);

export default TrustPilotCarousel;
