"use client";

import { FC } from "react";
import { SwiperSlide } from "swiper/react";

import ArticleCard, {
  IArticleCard,
} from "apps/website/components/feature/Card/ArticleCard/ArticleCard";
import Carousel from "apps/website/components/feature/Carousel/Carousel";

export interface IArticleCardCarousel {
  items: IArticleCard[]
}

const ArticleCardCarousel: FC<IArticleCardCarousel> = ({ items }) => (
  <Carousel component="ArticleCardCarousel" breakpoints={{ default: 1, md: 2, lg: 3 }}>
    { items.map((article) => (
      <SwiperSlide key={article.link} style={{ height: "auto" }}>
        <ArticleCard {...article} />
      </SwiperSlide>
    )) }
  </Carousel>
);

export default ArticleCardCarousel;
