import { FC, useCallback, useMemo } from "react";

import { Gender, ICatAge, ECatResides } from "@./cat-calculations";
import { LIST_FORMATTER_SHORT } from "apps/website/utils/misc/arrays";
import { themeRootClassMap } from "apps/website/maps/Theme.map";

import Image, { IImageProps } from "../../base/Image/Image";
import Container from "../../layout/Container/Container";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import TextTitle from "../../base/Text/TextTitle/TextTitle";
import Tag from "../../base/Tag/Tag";
import Spacer from "../../layout/Spacer/Spacer";
import TextBody from "../../base/Text/TextBody/TextBody";
import TaggedSummary from "../TaggedSummary/TaggedSummary";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";

export interface IFreshPlanOverviewCat {
  name: string;
  gender: Gender;
  age: ICatAge;
  weight: number;
  primarilyResides: ECatResides;
  chosenRecipes: string[];
  dailyCalories: number;
}

interface IFreshPlanOverviewReview {
  author: string;
  body: string;
}

export interface IFreshPlanOverviewProps {
  image: IImageProps;
  cats:
  | [IFreshPlanOverviewCat]
  | [IFreshPlanOverviewCat, IFreshPlanOverviewCat];
  price: number;
  review: IFreshPlanOverviewReview;
}

const FreshPlanOverview: FC<IFreshPlanOverviewProps> = (props) => {
  const isMultiCat = useMemo(() => props.cats.length > 1, [ props.cats ]);

  const catNames = useMemo(
    () => LIST_FORMATTER_SHORT.format(
      props.cats.map((mappedCat) => mappedCat.name),
    ),
    [ props.cats ],
  );

  const getAge = useCallback(
    (age: ICatAge) => {
      if (age.years && age.years >= 1) return `${age.years} years`;
      return `${age.months} months`;
    },
    [ props.cats ],
  );

  return (
    <div data-component={ FreshPlanOverview.name } className="pt-8 h-full">
      <Grid gap="none" className="h-full">
        <Column justify="center" align="center">
          <div className="relative z-10 w-[96px] h-[119px] lg:w-[193px] lg:h-[242px]">
            <Image image={ props.image } alt={ catNames } />
            <img
              src="/images/features/team/frame.png"
              alt=""
              height={ 230 }
              width={ 172 }
              className={
                "w-[96px] h-[119px] lg:w-[193px] lg:h-[242px] absolute top-0 left-0"
              }
            />
          </div>
        </Column>
        <Column>
          <Spacer size="lg" />
          <Grid
            className={ `before:bg-light-grey before:absolute before:-translate-y-full before:w-full before:h-[92px] lg:before:h-[160px] lg:before:h-[168px] transform -translate-y-1/1  ${themeRootClassMap["light-grey"]}` }
            theme="light-grey"
            gap="sm"
          >
            <Column direction="row" justify="center" align="center">
              <TextTitle size={ 2 }>{ catNames }</TextTitle>
              { isMultiCat && (
                <Tag size="small" theme="pink" className="ml-4">
                  Multi-cat
                </Tag>
              ) }
            </Column>
            <Column
              direction="row"
              justify="center"
              align="center"
              className={ isMultiCat ? "divide-x" : "" }
            >
              { props.cats.map((mappedCat) => (
                <>
                  <div className="px-4">
                    <TextBody tag="span" data-testid="FreshPlanOverviewAge">{ getAge(mappedCat.age) }</TextBody>
                  </div>
                  { !isMultiCat && (
                    <Tag size="small" theme="blue">
                      { mappedCat.age.type } cat
                    </Tag>
                  ) }
                </>
              )) }
            </Column>
          </Grid>
          <Grid
            theme="light-grey"
            className={ `h-full ${themeRootClassMap["light-grey"]}` }
          >
            <>
              { props.cats.map((mappedCat) => (
                <Column key={ mappedCat.name } spans={ isMultiCat ? { default: 6, lg: 12 } : 12 }>
                  <TaggedSummary
                    direction="row"
                    groups={ [
                      {
                        title: `${mappedCat.name}'s personalised plan`,
                        tags: [ `${mappedCat.dailyCalories} kcal/day` ],
                      },
                      {
                        title: "Based on",
                        tags: [
                          getAge(mappedCat.age),
                          `${mappedCat.weight}kg`,
                          mappedCat.gender,
                          mappedCat.primarilyResides,
                        ],
                      },
                      {
                        title: `${mappedCat.gender === "BOY" ? "His" : "Her"
                        } favourite recipes`,
                        tags: mappedCat.chosenRecipes,
                      },
                    ] }
                    theme="light-grey"
                  />
                </Column>
              )) }
              <Column>
                <Container size="md">
                  <Grid>
                    <Column
                      className={ `pb-2 -rotate-1 ${themeRootClassMap.brand}` }
                    >
                      <Grid gap="none">
                        <Column className="py-2 px-4">
                          <TextBody size={ 2 } align="center">
                            For just{ " " }
                            <span className="font-display" data-testid="FreshPlanOverviewPrice">
                              £{ props.price } per day
                            </span>
                          </TextBody>
                        </Column>
                        <Column>
                          <div
                            data-theme="dark"
                            className={ `p-4 ${themeRootClassMap.dark}` }
                          >
                            <TextSubtitle tag="h3" size={ 2 } align="center" data-testid="FreshPlanOverviewReviewAuthor">
                              { props.review.author }
                            </TextSubtitle>
                            <Spacer size="md" />
                            <TextBody align="center" size={ 2 } data-testid="FreshPlanOverviewReviewBody">
                              &ldquo;{ props.review.body }&rdquo;
                            </TextBody>
                          </div>
                        </Column>
                      </Grid>
                    </Column>
                  </Grid>
                </Container>
                <Spacer size="xl" />
              </Column>
            </>
          </Grid>
        </Column>
      </Grid>
    </div>
  );
};

export default FreshPlanOverview;
