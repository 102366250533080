"use client";

import { FC } from "react";

import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import TrustPilotCarousel from
  "apps/website/components/feature/Carousel/TrustPilotCarousel/TrustPilotCarousel";
import {
  pseudoThemeRootClassMap,
  Theme,
  themeRootClassMap,
} from "apps/website/maps/Theme.map";
import Text from "apps/website/components/base/Text/Text";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import {
  IExternalReviewCarousel,
} from "apps/website/components/feature/Carousel/ExternalReviewCarousel/ExternalReviewCarousel";
import Container from "apps/website/components/layout/Container/Container";
import Section from "apps/website/components/layout/Section/Section";
import { useTrustpilot } from "apps/website/hooks/useTrustpilot";

export interface ISectionTrustPilotCarousel extends IExternalReviewCarousel {
  title: string;
  body?: string;
  theme?: Theme;
}

const SectionTrustPilotCarousel: FC<ISectionTrustPilotCarousel> = ({ title, items, layout, theme = "dark" }) => {
  const { interpolate } = useTrustpilot();
  return (
    <Section component="SectionTrustPilotCarousel" theme={theme} className="overflow-hidden">
      <Container>
        <Grid gap="lg">
          <Column
            spans={{ lg: 4 }}
            className={`relative z-30 respect-index before:absolute before:right-0 before:top-0 before:-translate-y-1/2 before:translate-x-6  before:h-[200%] before:w-screen ${themeRootClassMap[theme]} ${pseudoThemeRootClassMap[theme]}`}
          >
            <Grid className="relative h-full">
              <Column justify="center" align={{ default: "center", lg: "start" }}>
                <Text tag="h2" size={legacySizeCollectionMap.titleMd} display="subtitle" align={{ default: "center", lg: "default" }}>{ interpolate(title) }</Text>
                { /* <Spacer size="md" />
                <Text size={legacySizeCollectionMap.bodyLg}>
                  <a
                    href={TRUST_PILOT_REVIEWS_URL}
                    target="_blank
                     rel="noopener
                     noreferrer"
                     className="underline underline-offset-8"
                  >
                    Rated { interpolate("$trustpilotScore") }/5 on Trustpilot
                  </a>
                </Text>
                <Spacer size="lg" />
                <div className="w-40">
                  <TrustPilotStars />
                </div> */ }
                <Spacer size="lg" />
              </Column>
            </Grid>
          </Column>
          <Column spans={{ lg: 8 }}>
            <TrustPilotCarousel items={items} theme={theme} layout={layout}/>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionTrustPilotCarousel;
