import { FC, PropsWithChildren } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import { Direction } from "apps/website/maps/Flex.map";

import TaggedSummaryGroup, {
  ITaggedSummaryGroupProps,
} from "./TaggedSummaryGroup";

export interface ITaggedSummaryProps {
  groups: ITaggedSummaryGroupProps[];
  theme?: Theme;
  direction?: Direction;
}

const TaggedSummary: FC<PropsWithChildren<ITaggedSummaryProps>> = ({
  groups,
  children,
  theme = "brand",
  direction = "column",
}) => (
  <div
    className={ `${themeRootClassMap[theme]} p-4 ${direction === "row" ? "lg:grid lg:grid-cols-12 lg:gap-4" : ""
    }` }
    data-component={ TaggedSummary.name }
  >
    { groups.map((group) => (
      <div
        key={ group.title }
        className={ `${direction === "row"
          ? "lg:col-span-4 lg:flex lg:flex-col lg:justify-start lg:items-center"
          : ""
        }` }
      >
        <TaggedSummaryGroup key={ group.title } { ...group } />
      </div>
    )) }
    <div data-children>{ children }</div>
  </div>
);

export default TaggedSummary;
