"use client";

import { FC } from "react";
import { SwiperSlide } from "swiper/react";

import Carousel from "apps/website/components/feature/Carousel/Carousel";
import ExternalReview, {
  IExternalReview,
} from "apps/website/components/feature/Review/ExternalReview/ExternalReview";
import { Layout } from "apps/website/components/feature/Review/Review.map";

export interface IExternalReviewCarousel {
  items: IExternalReview[];
  layout?: Layout;
}

const ExternalReviewCarousel: FC<IExternalReviewCarousel> = ({ items, layout }) => (
  <Carousel component="ExternalReviewCarousel" breakpoints={{ default: 1.1 }} loop overflow="dim" centeredSlides>
    { items.map((review) => (
      <SwiperSlide key={review.title}>
        <ExternalReview {...review } layout={layout} />
      </SwiperSlide>
    )) }
  </Carousel>
);

export default ExternalReviewCarousel;
