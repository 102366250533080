import { FC, ReactNode, memo } from "react";

import { WithDataAttr } from "apps/website/types";
import cx from "apps/website/utils/misc/cx";

import { TTitleTag } from "../types";

import textTitleStyles, { TextTitleVariantsProps } from "./styles";

export interface ITitleProps extends WithDataAttr, TextTitleVariantsProps {
  id?: string;
  tag?: TTitleTag
  children: ReactNode;
  className?: string;
}

const TextTitle: FC<ITitleProps> = ({
  id,
  tag: Tag = "h2",
  children,
  "data-testid": testId,
  size,
  align,
  whitespace,
  color,
  weight,
  className,
}) => (
  <Tag
    id={id}
    data-testid={testId}
    className={cx(textTitleStyles({ size, align, whitespace, color, weight }), className)}
  >
    { children }
  </Tag>
);

export default memo(TextTitle);
